html{
  font-size:16px;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button {
  margin: 0;
  padding: 0;
}

fieldset, img {
  border: 0 none;
}

dl, ul, ol, menu, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

input, select, textarea, button {
  vertical-align: middle;
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

body {
  background: #fff;
}

body, th, td, input, select, textarea, button {
  line-height: 1.5;
  font-family: '돋움', dotum, sans-serif;
  color: #333;
}

a {
  color: #333;
  text-decoration: none;
  &:active,&:hover{
    text-decoration: underline;
  }
}

address, caption, cite, code, dfn, em, var {
  font-style: normal;
  font-weight: normal;
}