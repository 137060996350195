@charset "UTF-8";
/* line 1, public/src/scss/reset.scss */
html {
	font-size: 16px;
}

/* line 5, public/src/scss/reset.scss */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button {
	margin: 0;
	padding: 0;
}

/* line 10, public/src/scss/reset.scss */
fieldset, img {
	border: 0 none;
}

/* line 14, public/src/scss/reset.scss */
dl, ul, ol, menu, li {
	list-style: none;
}

/* line 18, public/src/scss/reset.scss */
blockquote, q {
	quotes: none;
}

/* line 22, public/src/scss/reset.scss */
input, select, textarea, button {
	vertical-align: middle;
}

/* line 26, public/src/scss/reset.scss */
button {
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
}

/* line 32, public/src/scss/reset.scss */
body {
	background: #fff;
}

/* line 36, public/src/scss/reset.scss */
body, th, td, input, select, textarea, button {
	line-height: 1.5;
	font-family: '돋움', dotum, sans-serif;
	color: #333;
}

/* line 42, public/src/scss/reset.scss */
a {
	color: #333;
	text-decoration: none;
}

/* line 45, public/src/scss/reset.scss */
a:active, a:hover {
	text-decoration: underline;
}

/* line 50, public/src/scss/reset.scss */
address, caption, cite, code, dfn, em, var {
	font-style: normal;
	font-weight: normal;
}
